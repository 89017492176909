// import data from "data/notifications";
import { getCurrentUser, log } from "helpers/Utils";

export const UserRole = {
  Admin: 1,
  Executive: "False",
  Expert:"True",
  HCR_User :"HCR_EXECUTIVE",
  Mailing_Telecaller: "MAILING_TELECALLER",
  Mailing_Super_Telecaller: "MAILING_SUPER_TELECALLER",
  Ombudsman_Telecaller: "OMBUDSMAN_TELECALLER",
  Ombudsman_Super_Telecaller: "OMBUDSMAN_SUPER_TELECALLER",
};


export const adminStatusChangePasswords =  {
  'Shilpa Mam': '1234',
  'Ruchi Mam': '9876',
  'Bhavana': '2003',
}

export const newExecutives = ["MAILING_TELECALLER", "MAILING_SUPER_TELECALLER", "OMBUDSMAN_TELECALLER","OMBUDSMAN_SUPER_TELECALLER"];

export const executivesMail = {
  chiragMail : "chirag@insurancesamadhan.com",
}

export const callingTeamAccess = ['INSA_CHIRAG1607922823688','INSA_LALIT1612263059377','INSA_SAHIL1597734791810']

export const internalLegalAdvocate = [{
  label:'Ghulam', value:'INSA_GHULAM1644922967643'},{

  label:'Vaishali', value: 'INSA_VAISHALI1633694391548'},{

  label:'Tanya', value: 'INSA_TANYA1633943050600'},
  ]

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

// export const currentUser = {
//   id: 1,
//   title: data.userType,
//   img: '/assets/img/profiles/user.png',
//   // date: 'Last seen today 15:24',
//   role: UserRole.Admin,
// };

export let currentUser = getCurrentUser();

export function setCurrentUserFunc() {
  currentUser = getCurrentUser()
  log(currentUser)
}

export const adminRoot = '/app';
export const documentRoot = "/document"
export const authRoot = '/user/login';
export const buyUrl = '/';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;

export const apiEndpoints = {
  login: "/admin/login",
  createToken: '/createToken'
}
 

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueyale';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

const env = process.env.REACT_APP_ENV || "local";
// const port = process.env.REACT_APP_PORT;

let backendApi, agentApi, mceKey, awsKey, adminLogin, cronsUrl, sentryObj = {};
export const partnertoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqd3RJZCI6MTY1ODc0MzkyODY5MCwiaXNzdWVEYXRlIjoiMjAyMi0wNy0yNVQxMDoxMjowOC42OTBaIiwidXNlcklkIjoiY2ZhZDlkMDEtNGJlYi00MjYxLTlmNTgtNjU3YzUyODdmMTRiIiwiZW1haWwiOiJtYW5pc2hAaW5zdXJhbmNlc2FtYWRoYW4uY29tIiwibW9iaWxlIjoiOTgxMDg5MTQ3NCIsIm5hbWUiOiJTdXJhaiBNYW5hZ2VyIiwidXNlclR5cGUiOiJNYW5hZ2VyIiwidXNlck5hbWUiOiJJTlNBTUFOQUdFUjE1OTg1NTM4MTk3NzIiLCJoaWVyYXJjaGljYWxMZXZlbCI6MCwiaWF0IjoxNjU4NzQzOTI4LCJleHAiOjE2OTAyNzk5Mjh9.qjPUZpRIKsivoPSMXpK_qXda42LaglXoDnZxu7SWiqE";

const tinyMceKeys = {
  Official:'0sj8gdgjk9z142gxzuv3f5t0meq0q6gxvuowe0avsommw26a',
  Sumit:'6kf37mw5vz6pelozivaqya08neminbb1uvq901f5865hhktl',
  Faiz:'ol7fvznm4euy7tmthq3ndmigcyqjhmadtmv57wlbxsrwqd9o',
  Anuj:'95f2upc2vjxtq4tukw4k798pb36pjsvxgfvv05yx7cxfr5a9',
  SumitIntern:'6pumjjz16oaqnh478av8xi152npen7598monut10tdub8g0x',
  Satish:"0piu6805kwbe4tk3p1vnegdsck9aitqsqmhnpdpkh9l645d6"
}

switch (env) {
  case 'dev':
    backendApi = 'https://api.dev.insurancesamadhan.com';
    agentApi = "https://api.dev.insurancesamadhan.com/agentsApi"
    mceKey = tinyMceKeys.Faiz;
    awsKey = 'https://staging-insa.s3.ap-south-1.amazonaws.com';
    adminLogin = ["ravi@insurancesamadhan.com",'mailing@insurancesamadhan.com','legal@insurancesamadhan.com','ombudsman@insurancesamadhan.com'];
    cronsUrl = "https://cron.dev.insurancesamadhan.com";
    sentryObj = {
      tracePropagationTargets: ["admin.dev.insurancesamadhan.com"],
      environment : "dev"
    }
    break;
  case 'stage':
    backendApi = 'https://api.stage.insurancesamadhan.com';
    agentApi = "https://api.stage.insurancesamadhan.com/agentsApi"
    mceKey = tinyMceKeys.Anuj
    awsKey = 'https://staging-insa.s3.ap-south-1.amazonaws.com';
    adminLogin = ["ravi@insurancesamadhan.com",'mailing@insurancesamadhan.com','legal@insurancesamadhan.com','ombudsman@insurancesamadhan.com'];
    cronsUrl = "https://cron.stage.insurancesamadhan.com";
    sentryObj = { tracePropagationTargets: ["admin.stage.insurancesamadhan.com"], environment : "stage" };
    break;
  case 'prod':
    backendApi = 'https://api.insurancesamadhan.com';
    agentApi = 'https://api.insurancesamadhan.com/agentsApi';
    mceKey = tinyMceKeys.Official;
    awsKey = 'https://insurance-samadhan-docs.s3.ap-south-1.amazonaws.com';
    adminLogin = ["corporate@insurancesamadhan.com", "jitesh@insurancesamadhan.com",'mailing@insurancesamadhan.com','legal_admin@insurancesamadhan.com','ombudsman@insurancesamadhan.com']
    cronsUrl = "https://cron.insurancesamadhan.com";
    sentryObj = { tracePropagationTargets: ["admin.insurancesamadhan.com"], environment : "prod" }
    break;
  default:
    backendApi = 'http://localhost:3999';
    agentApi = 'http://localhost:3999/agentsApi';
    mceKey = '6kf37mw5vz6pelozivaqya08neminbb1uvq901f5865hhktl';
    awsKey = 'https://staging-insa.s3.ap-south-1.amazonaws.com';
    adminLogin = ["ravi@insurancesamadhan.com",'mailing@insurancesamadhan.com','legal@insurancesamadhan.com','ombudsman@insurancesamadhan.com'];
    cronsUrl = "http://localhost:5000";
    sentryObj = { tracePropagationTargets: ["localhost", "http://localhost:3999"], environment : "local" }
}

export const servicePath = backendApi;
export const cronsPath = cronsUrl;
export const agentApiPath = agentApi;
export const tinyMceApiKey = mceKey;
export const awsUrl = awsKey;
export const adminAccount = adminLogin;
export const sentryConfig = sentryObj;

let partnerApi;

switch (env) {
  case "local":
    partnerApi = "https://partnersapi.stage.insurancesamadhan.com/api/v1"
    break;

  case ("stage"):
    partnerApi = "https://partnersapi.stage.insurancesamadhan.com/api/v1"
    break;

  default:
}
export const partnerServicePath = partnerApi

export const signDeskFollowDate = 1689578305471